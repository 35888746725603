<template>
    <div>
        <v-card v-if="datos.direccionFacturacion && datos.direccionFacturacion.includes('ATLANTIS')">
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-card-text class="pb-0">
                    <v-row v-if="datos.barCodeGenerate" dense>
                        <v-col v-for="(item, key) in datos.barCodeGenerate" :key="key" cols="12">
                            <h2 class="green--text pb-3">
                                Secuencial: <strong>{{ item.code }}</strong>
                            </h2>
                            <v-row dense>
                                <v-col cols="12" sm="2" class="columna">
                                    <v-text-field
                                        readonly
                                        type="number"
                                        dense
                                        v-model="datos.idOrdenCompra"
                                        label="Nro Orden de Compra"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2" class="columna">
                                    <v-text-field outlined dense type="number" v-model="item.bultos" label="Cantidad de Bultos" required></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2" class="columna">
                                    <v-btn :loading="loader && itemSelector == key" color="teal accent-4" @click="printReport(item, key)">Imprimir</v-btn>
                                </v-col>
                                <v-col cols="12" sm="2" class="columna">
                                    <v-btn :loading="loaderTag && itemSelector == key" color="orange" @click="print_tag(item, key)">Etiqueta</v-btn>
                                </v-col>
                                <v-col cols="12" sm="2" class="columna">
                                    <v-btn
                                        :loading="loaderDate && itemSelector == key"
                                        :disabled="!datos.numeroGuiaRemision"
                                        color="blue"
                                        @click="send_date(item, key)"
                                        >Generar Cita</v-btn
                                    >
                                </v-col>
                                <v-col cols="12" sm="2" class="columna">
                                    {{ `El estado del ASN es: "${statusASN}"` }}
                                    <v-btn :loading="loaderSatus && itemSelector == key" :disabled="statusASN" color="grey" @click="check_status(item, key)"
                                        >Comprobar Estado</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-col>
                        <h2 v-if="!datos.numeroGuiaRemision">Se necesita generar primero la guia de remisión</h2>
                    </v-row>
                </v-card-text>
            </v-form>
        </v-card>
        <v-card elevation="0" v-else>
            <v-card-text>
                <h1>Solo puede observar este apartado las ventas hechas por SODIMAC</h1>
                <h3 class="py-2">Detalle Envio: {{ datos.direccionFacturacion }}</h3>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import request from '@/utils/request'
import { sleep } from '@/utils/genericUtils'

export default {
    created() {
        if (this.datos.direccionFacturacion && this.datos.direccionFacturacion.split('-').includes('CD ATLANTIS ')) {
            let barCode = []
            if (!this.datos.barCodeGenerate) {
                this.getLastcodeBar()
            }
            for (const item of this.datos.barCodeGenerate) {
                barCode.push({ ...item, bultos: item.bultos ?? 0 })
            }
            this.datos.barCodeGenerate = barCode
        }
    },
    data: () => ({
        textChecker: '',
        labelCodeBar: '',
        barCodeGenerado: '',
        loader: null,
        loaderTag: false,
        loaderDate: false,
        loaderSatus: false,
        reveal: false,
        loading: false,
        valid: true,
        codeBar: '',
        idOrdenCompra: '',
        bulto: [],
        bultoLeft: '',
        bultoRight: '',
        name: '',
        email: '',
        itemSelector: null,
        item: [],
        select: null,
        checkbox: false
    }),
    computed: {
        ...mapState('ventas', ['modalReserva', 'datos', 'productos']),
        statusASN() {
            return this.datos?.atributos?.statusASN || null
        }
    },
    methods: {
        async continuar() {
            //console.log('codigoBarra', this.barCodeGenerado)
            this.reveal = this.datos.idOrdenCompra && this.bulto > 0 ? true : false
        },
        async getLastcodeBar() {
            let code = []
            console.log('this.datos---', this.datos)
            if (this.datos?.barCodeGenerate) {
                for (const item of this.datos.barCodeGenerate) {
                    const data = {
                        ...item,
                        bultos: this.bulto
                    }
                    code.push(data)
                }
            }
            console.log('code---', code)
        },
        async printReport(payload, indice) {
            this.itemSelector = indice
            this.loader = true
            if (payload.bultos >= 1) {
                for (let i = 1; i <= payload.bultos; i++) {
                    this.item.push({ idOrdenCompra: this.datos.idOrdenCompra, bultoLeft: i, bultoRight: payload.bultos })
                }
            } else {
                this.item.push({ idOrdenCompra: this.datos.idOrdenCompra, bultoLeft: 0, bultoRight: 0 })
            }
            //console.log('item ', this.item)
            await this.$store.dispatch('reporte/ticketSodimac', { idVenta: this.datos.id, item: this.item, CodeBar: payload.code, sku: payload.skuSodimac })
            await this.$store.dispatch('ventas/patchVenta', { ...this.datos })
            this.$store.commit('mainUI/OPEN_MODAL', { state: false })
            this.item = []
            this.bulto = []

            this.reveal = false

            this.loader = false
            this.$store.commit('ventas/SHOW_MODAL', { step: 1, state: false })
        },

        async print_tag(payload, indice) {
            console.log('payload-.-.-.', payload)
            this.itemSelector = indice
            this.loaderTag = true
            //console.log('this.datos ', this.datos)
            await this.$store.dispatch('reporte/generatedCodeBar', {
                descSodimac: payload.descSodimac,
                upcSodimac: payload.upcSodimac,
                skuSodimac: payload.skuSodimac
            })
            await this.$store.dispatch('ventas/patchVenta', { ...this.datos })
            this.$store.commit('mainUI/OPEN_MODAL', { state: false })
            this.reveal = false
            this.loaderTag = false
            this.$store.commit('ventas/SHOW_MODAL', { step: 1, state: false })
        },
        async send_date(payload, indice) {
            this.itemSelector = indice
            this.loaderDate = true
            const numeroGuia = this.datos.numeroGuiaRemision.split('-')[1].trim()
            console.log('const numeroGuia = this.datos.', this.datos.atributos)
            const { upcSodimac, code } = this.datos.barCodeGenerate[indice]
            await this.$store.dispatch('ventas/CrearCitaOrdenCompra', {
                ordenCompra: this.datos.idOrdenCompra,
                numeroGuia,
                idVenta: this.datos.id,
                upc: upcSodimac,
                units: this.datos.productos.length,
                bultosSodimac: parseInt(this.datos.atributos.bultosSodimac),
                barcode: code
            })
            await this.$store.dispatch('ventas/patchVenta', { ...this.datos })
            this.$store.commit('mainUI/OPEN_MODAL', {
                state: true,
                text: 'ASN generado, verificar estado en el b2b, en unos minutos',
                color: 'green'
            })
            await sleep(3000)
            this.$store.commit('mainUI/OPEN_MODAL', { state: false })
            this.reveal = false
            this.loaderDate = false
        },
        async check_status(payload, indice) {
            this.itemSelector = indice
            this.loaderSatus = true
            const { atributos } = await this.$store.dispatch('ventas/ventaProducto', this.datos)
            let text = ``
            console.log('atributos?.statusASN', atributos?.statusASN)
            if (atributos?.statusASN) {
                text = `El estado del ASN es: "${atributos.statusASN}"`
            } else {
                await this.$store.dispatch('reporte/consultStatus', this.datos.id)
                text = ` Aun se esta generando la carga del ASN`
            }
            this.textChecker = text
            this.$store.commit('mainUI/OPEN_BAR', {
                state: true,
                text,
                color: 'green'
            })
            this.reveal = false
            this.loaderSatus = false
        },
        async Retornar() {
            this.reveal = false
        }
    },
    async mounted() {
        this.textChecker = ''
        this.bulto = this.productos.length
    }
}
</script>

<style>
.v-card--reveal {
    bottom: 0;
    opacity: 1 !important;
    position: absolute;
    width: 100%;
}

.columna {
    margin-right: 50px;
    margin-top: 20px;
}
</style>
